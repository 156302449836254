import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './css/index.css';
import './css/main.css';

// pages
import RegWN from './Pages/registration'
import Recover from './Pages/recover'

import './i18n';

ReactDOM.render(
  <React.StrictMode>
    <Router>
        <Switch>
          <Route exact path="/" component={RegWN} />
          <Route path="/:locale/registration" component={RegWN} />
          <Route path="/:locale/recover" component={Recover} />
        </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);