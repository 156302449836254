import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "password": {
        "1": "Password",
        "2": "Confirm password"
      },
      "phone":  {
        "1": "Mobile",
        "2": "5__ ___ ___"
      },
      "terms":  {
        "1": "I certify that I am of legal age (18+), ",
        "2": "I have read and agree to the ",
        "3": "Terms of Service ",
        "4": "and ",
        "5": "Privacy Policy"
      },
      "privacy":  {
        "1": "I agree to the ",
        "2": "Privacy Policy"
      },
      "submit": "Next",
      "smsCode": "SMS code",
      "registration": "Registration",
      "smsResend": "Resend SMS",
      "msg_invalid_number": "Mobile number already registered or incorrect",
      "msg_invalid_password": "Passwords must match",
      "msg_invalid_password2": "Password requirements not met",
      "msg_password_text": "Password must contain:",
      "msg_password_validator": "At least 6 characters, upper and lowercase letters and at least 1 digit, without space",
      "msg_reg_terms": "You have to agree Terms And Services to register",
      "msg_sms_sended": "SMS code has been sent",
      "msg_sms_resend": "SMS code has been resend",
      "msg_sms_resend_stoper": "SMS code resend can be",
      "msg_send_error": "An error occurred while sending the data",
      "msg_acc_created": "Account created successfully",
      "msg_wrong_smscode": "Wrong SMS code",
      "msg_empty_smscode": "Empty SMS code",
      "msg_email_sent": "Password reset link has been sent to your Email.",
      "msg_sms_sent": "SMS reset code has been sent to your mobile number.",
      "msg_password_success": "Password successfully reset. Please note, you will be able to sign in the system in several minutes after password reset.",
      "msg_sent_error": "User has not been found with this E-mail, username or mobile number. Please check the data you entered",
      "msg_sms_error": "Wrong SMS code.",
      "recover": "Recover",
      "change-password": "Change password",
      "recovery":  {
        "1": "Password Recovery",
        "2": "Mobile, Email or Username"
      },
      "newReg": "For registration visit Silktv.ge"
    }
  },
  ka: {
    translation: {
      "password": {
        "1": "პაროლი",
        "2": "გაიმეორე პაროლი"
      },
      "phone":  {
        "1": "მობილური",
        "2": "5__ ___ ___"
      },
      "terms":  {
        "1": "ვადასტურებ, რომ ვარ სრულწლოვანი (18+), ",
        "2": "წავიკითხე და ვეთანხმები ",
        "3": "მომსახურების პირობებსა ",
        "4": "და ",
        "5": "კონფიდენციალურობის პოლიტიკას"
      },
      "submit": "გაგრძელება",
      "smsCode": "SMS კოდი",
      "registration": "რეგისტრაცია",
      "smsResend": "SMS კოდის განახლება",
      "msg_invalid_number": "ნომერი უკვე რეგისტრირებულია ან არასწორია",
      "msg_invalid_password": "პაროლები უნდა ემთხვეოდეს ერთმანეთს",
      "msg_invalid_password2": "პაროლები არ აკმაყოფილებს მოთხოვნებს",
      "msg_password_text": "პაროლი უნდა შეიცავდეს:",
      "msg_password_validator": "მინიმუმ 6 სიმბოლოს, დიდ და პატარა ლათინურ ასოებს და მინიმუმ 1 ციფრს, გამოტოვების გარეშე",
      "msg_reg_terms": "რეგისტრაციისთვის აუცილებელია დაეთანხმოთ მომსახურების პირობებს",
      "msg_sms_sended": "SMS კოდი გამოგზავნილია",
      "msg_sms_resend": "SMS კოდი განახლებულია",
      "msg_sms_resend_stoper": "SMS კოდის განახლებას შეძლებთ",
      "msg_send_error": "მონაცემების გაგზავნისას დაფიქსირდა შეცდომა",
      "msg_acc_created": "თქვენი ანგარიში შეიქმნა წარმატებით",
      "msg_wrong_smscode": "SMS კოდი არასწორია",
      "msg_empty_smscode": "SMS ველი ცარიელია",
      "msg_email_sent": "პაროლის აღდგენის ბმული გამოგზავნილია მითითებულ ელ-ფოსტაზე.",
      "msg_sms_sent": "SMS კოდი გამოგზავნილია მითითებულ მობილურ ნომერზე.",
      "msg_password_success": "პაროლი აღდგენილია. გთხოვთ გაითვალისწინოთ, რომ სისტემაში ავტორიზაციას შეძლებთ პაროლის შეცვლიდან რამდენიმე წუთში.",
      "msg_sent_error": "მითითებული ელ-ფოსტით, სახელით ან ნომრით მომხმარებელი ვერ მოიძებნა. გთხოვთ გადაამოწმოთ მონაცემების სისწორე",
      "msg_sms_error": "არასწორი SMS კოდი.",
      "recover": "აღდგენა",
      "change-password": "პაროლის შეცვლა",
      "recovery":  {
        "1": "პაროლის აღდგენა",
        "2": "მობილური, ელფოსტა ან მომხ.სახელი"
      },
      "newReg": "რეგისტრაციისთვის ეწვიეთ Silktv.ge-ს"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'ka',
    debug: false,

    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;
